
import { useRoute, useRouter } from 'vue-router'
import HwItem from './HwItem.vue'
import usePageInfo from './usePageInfo'
import { changeShareInfo } from '@/utils/wechat'

export default {
  components: {
    HwItem
  },
  setup: () => {
    const route = useRoute()
    const router = useRouter()
    const { courseId } = route.params
    const { pageInfo } = usePageInfo({ courseId })
    changeShareInfo({
      title: '实战练习',
      desc: process.env.VUE_APP_PAGE_DESC,
      link: window.location.href,
      imgUrl: pageInfo.value.exampleVideoCover
    })
    return {
      pageInfo,
      gotoUploadPage: () => {
        router.push(`/homework/upload/${courseId}`)
      },
      deleteSuccess: (idx: number) => {
        pageInfo.value.cpworks.splice(idx, 1)
      }
    }
  }
}
