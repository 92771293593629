import moment from 'moment'

const fromNow = (dateStr: string, dateFormat = 'YYYY-MM-DD hh:mm:ss') => moment(dateStr, dateFormat).fromNow()

const formatDate = (d: string | Date, dateFormat = 'YYYY-MM-DD') => moment(d).format(dateFormat)

const formatDate2 = (d: string | Date, dateFormat = 'YYYYMMDD') => moment(d).format(dateFormat)

const formatDate4CH = (d: string | Date, dateFormat = 'YYYY年MM月DD日') => moment(d).format(dateFormat)

const formatDate4Month = (d: string | Date, dateFormat = 'YYYYMM') => moment(d).format(dateFormat)

export {
  fromNow,
  formatDate,
  formatDate4CH,
  formatDate2,
  formatDate4Month
}
