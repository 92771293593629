import { ref } from 'vue'
import { Homework } from 'momai'
import { api } from '@/api/useAxios'

export default ({ courseId }: {
  courseId: string | string[];
}) => {
  const pageInfo = ref<{
    commitedWorksCnt: number;
    haveCpHomeworks: boolean;
    haveFailHomeworks: boolean;
    courseInfoId: number;
    cpworks: Homework[];
    failworks: Homework[];
    exampleVideo: string;
    exampleVideoCover: string;
    workRequire: string;
  }>({
    commitedWorksCnt: 0,
    courseInfoId: 0,
    cpworks: [],
    failworks: [],
    exampleVideo: '',
    exampleVideoCover: '',
    haveCpHomeworks: false,
    haveFailHomeworks: false,
    workRequire: ''
  })

  api.post('/homework/getCourseInfoAndHwList', {
    courseInfoId: courseId
  }).then(r => {
    pageInfo.value = r
  })
  return {
    pageInfo
  }
}
