
import { PropType, defineComponent, toRefs, computed } from 'vue'
import { fromNow } from '@/utils/format'
import { Dialog, Toast } from 'vant'
import { api } from '@/api/useAxios'
import { Homework } from 'momai'

export default defineComponent({
  props: {
    hw: {
      type: Object as PropType<Homework>
    }
  },
  setup: (props, { emit }) => {
    const { hw } = toRefs(props)
    const showDot = computed(() => hw?.value?.unread === '0')
    return {
      showDot,
      fromNow,
      askDel: ({ id }: Homework) => {
        Dialog.confirm({
          message: '确认删除吗？',
          title: '确认'
        }).then(() => {
          api.post('/homework/delete', [id]).then(() => {
            emit('delete-success')
            Toast.success('删除成功！')
          })
        }).catch(() => {})
      }
    }
  }
})
